@import './2023Base.scss';

.inline-toggle {
    display: flex;
    align-items: center;
}

.event-panel-container {
    display: flex;
    flex-flow: row;
    .event-panel {
        display: flex;
        flex-flow: column nowrap;
        width: 300px;
        min-height: 400px;
        border: 2px solid $BLUE1;
        border-radius: 20px;
        margin: 40px;
        .event-panel-title {
            background-color: $BLUE1;
            color: white;
            text-align: center;
            font-weight: 600;
            font-size: 24px;
            border-radius: 16px 16px 0 0;
        }
        .event-panel-image {
            background-color: $LIGHTGRAY;
            img {
                width: 280px;
                margin: 10px;
            }
        }
        .event-panel-dates {
            background-color: $BLUE1;
            color: white;
            text-align: center;
            font-weight: 500;
            font-size: 24px;
            margin: 0 0 10px 0;
        }
        .event-panel-button {
            display: flex;
            flex-flow: column;
            padding: 10px;
            border-bottom: 1px solid $DARKGRAY;
            align-items: center;
            p {
                text-align: center;
                font-weight: 600;
            }
            &:last-child {
                border: none;
            }
        }
    }
    .event-panel-settings {
        display: flex;
        flex-flow: column;
    }
}