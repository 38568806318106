@import 'style/2023Base';

.website-design-studio {
    display: flex;
    flex-flow: column nowrap;
//    height: 100vh;
    background-color: red;
    position: fixed;
    top: 75px;
    bottom: 0;
    left: 0;
    right: 0;
    .website-settings-area {
        display: flex;
        justify-content: flex-end;
        padding: 10px;
        //background-color: $LIGHTGRAY;
        align-items: center;
    }
    .website-editor-container {
        display: flex;
        flex: 1;
        overflow: hidden;
        height: 100%;
        .website-preview-area {
            display: flex;
            flex-flow: column nowrap;
            flex: 1;
            margin: 0 10px 10px;
            background-color: $LIGHTGRAY;
            justify-content: center;
            overflow: auto;
            .website-preview {
                width: 100%;
                height: 100%;
                border: 1px solid $DARKGRAY;
                .website-webpage-area {
                    display: flex;
                    flex-flow: column;
                    .website-header {
                        display: flex;
                        min-height: 100px;
                        align-items: center;
                        ul {
                            display: flex;
                            list-style: none;
                            margin: 20px auto;
                            padding: 0;
                            li {
                                margin: 0 10px;
                                width: 100px; // Set a fixed width that accommodates the bold text
                                text-align: center; // Center the text within the fixed width
                                &.selected {
                                    font-weight: 800;
                                }
                                &:hover {
                                    font-weight: 800;
                                    cursor: pointer;
                                }
                            }
                        }
                    }
                    .website-page {
                        display: flex;
                        flex-flow: column nowrap;
                        flex: 1;
                        width: 100%;
                        .mt-page {
                            display: flex;
                            flex-flow: column nowrap;
                            flex: 1;
                            //height: 100%;
                            //min-height: 200px;
                            .mt-section {
                                display: flex;
                                flex-flow: row nowrap;
                                flex: 1;
                                min-height: 100px;
                                align-items: center;
                                cursor: pointer;
                                &.selected.selected {
                                    box-shadow: inset 0 0 0 2px $BLUE1!important;
                                }
                                &:hover {
                                    box-shadow: inset 0 0 0 2px $BLUE2!important;
                                }
                                .mt-column {
                                    display: flex;
                                    flex-flow: column nowrap;
                                    flex: 1;
                                    justify-content: space-between;
                                    padding: 10px;
                                    .mt-element {
                                        position: relative;
                                        display: flex;
                                        justify-content: space-around;
                                        padding: 0;
                                        margin: 0;
                                        cursor: pointer;
                                        &.selected {
                                            box-shadow: inset 0 0 0 2px $BLUE1;
                                        }
                                        &:hover {
                                            box-shadow: inset 0 0 0 2px $BLUE2;
                                        }
                                        .mt-image-container {
                                            background-size: contain;
                                            background-repeat: no-repeat;
                                            width: 400px;
                                            height: 400px;
                                        }
                                        .drop-bubble {
                                            position: absolute;
                                            left: 50%;
                                            transform: translateX(-50%);
                                            background-color: #2599C4;
                                            color: #fff;
                                            padding: 2px 8px;
                                            border-radius: 4px;
                                            font-size: 12px;
                                            white-space: nowrap;
                                            & .drop-bubble-top {
                                                top: -10px;
                                            }
                                            &.drop-bubble-bottom {
                                                top: 100%;
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                    .website-footer {
                        display: flex;
                        min-height: 80px;
                        align-items: center;
                        padding: 0 10px;
                        img {
                            height: 50px;
                        }
                    }
                }
            }
        }
        .website-tools-area {
            display: flex;
            flex-flow: column nowrap;
            overflow: auto;
            background-color: $LIGHTGRAY;
            justify-content: center;
            align-items: center;
            height: 100%;
            width: 320px;
            border-left: 1px solid $MIDGRAY;
            margin: 0 10px;
            .tiles-view {
                display: flex;
                flex-flow: column nowrap;
                flex-grow: 1;
                .tiles {
                    display: flex;
                    flex-flow: row wrap;
                    .tile {
                        width: 100px;
                        display: flex;
                        flex-flow: column nowrap;
                        justify-content: center;
                        align-items: center;
                        border: 1px solid $DARKGRAY;
                        border-radius: 5px;
                        margin: 5px;
                        padding: 5px;
                        cursor: grab;
                        .tile-icon {
                            font-size: 48px;
                        }
                        .tile-label {

                        }
                        &:hover {
                            background-color: $MIDGRAY;
                            color: white;
                        }
                    }
                }
            }
            .website-settings {
                display: flex;
                flex-flow: column nowrap;
                width: 80%;
                .website-setting {
                    display: flex;
                    flex-flow: column nowrap;
                    border: 1px solid $DARKGRAY;
                    border-radius: 10px;
                    width: 100%;
                    padding: 10px;
                    margin: 5px;
                }
            }
            .selection-details {
                display: flex;
                flex-flow: column nowrap;
                flex: 1;
                width: 100%;
                .selection-actions {
                    display: flex;
                    background-color: $DARKGRAY;
                    color: white;
                    justify-content: space-between;
                }
                .module-settings-tabs {
                    display: flex;
                    flex-flow: row nowrap;
                    justify-content: space-around;
                    .module-settings-tab {
                        display: flex;
                        flex-flow: column;
                        flex: 1;
                        border: 1px solid $DARKBORDER;
                        justify-content: center;
                        align-items: center;
                        padding: 10px 0;
                        cursor: pointer;
                        .module-settings-tab-label {
                            margin-top: 4px;
                        }
                        &.selected {
                            color: $BLUE1;
                            cursor: unset;
                        }
                    }
                }
            }
        }
    }
}

.module-setting {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    margin: 2px 4px 4px;
    &.centered {
        justify-content: center;
    }
    .button-setting-buttons {
        display: flex;
        flex-flow: row nowrap;
        background-color: $DARKBLOCK;
        border: 1px solid $DARKBORDER;
        border-radius: 5px;
        .setting-button {
            display: flex;
            justify-content: center;
            border-right: 1px solid $DARKBORDER;
            margin: 4px 6px;
            cursor: pointer;
            &:last-child {
                border-right: none;
            }
            &.selected {
                color: $BLUE1;
            }
        }
    }
}