@import 'style/_base';

.image-viewer {
    display: flex;
    border: 1px solid $DARKGRAY;
    overflow-x: auto;
    height: 370px;
    .image-view-container {
        width: 300px;
        height: 300px;
        border: 1px solid $MIDGRAY;
        margin: 10px;
        .image-view-image {
            background-size: contain;
            background-repeat: no-repeat;
            background-position: center;
            width: 300px;
            height: 300px;
            margin-bottom: 5px;
        }
        .image-view-buttons {
            display: flex;
            justify-content: center;
        }
    }
}

.single-image-view {
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    width: 300px;
    height: 300px;
    margin-bottom: 5px;
    border: 1px solid $MIDGRAY;
}