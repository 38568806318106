@import 'style/2023Base.scss';

.customers-page {
    margin: 20px;
    .customers-container {
        display: flex;
        flex-flow: column;
        .customers-controls {
            display:flex;
            flex-flow:row;
            border: 1px solid $BLUE1;
            margin-bottom: 5px;
            padding:10px;
            .customer-control {
                display: flex;
                flex-flow: column;
                justify-content: flex-end;
                min-width: 250px;
                margin: 0 10px;
            }
        }
        .customers-table {
            width: 100%;
            tr {
                th {
                    background-color: $BLUE3;
                    border-top: 1px solid $BLUE1;
                    border-bottom: 1px solid $BLUE1;
                    border-left: 1px solid $BLUE1;
                    padding: 5px;
                    &:last-child {
                        border-right: 1px solid $BLUE1;
                    }
                }
                td {
                    border-bottom: 1px solid $BLUE1;
                    border-left: 1px solid $BLUE1;
                    padding: 5px;
                    &:last-child {
                        border-right: 1px solid $BLUE1;
                    }
                }
                &.customer-row {
                    cursor: pointer;
                    &:hover {
                        background-color: $BLUE2;
                    }
                }
                &.customer-details-row {
                    tr {
                        cursor: pointer;
                        &:hover {background-color: $BLUE2;}
                    }
                }
            }
        }
    }
}