
// Primary Palette
$CORAL1: #C45025;
$CORAL2: #E7B7A6;
$CORAL3: #F7E7E1;
$NAVY1: #273649;
$NAVY2: #B9BDC3;
$NAVY3: #E2E4E7;
$BLUE1: #2599C4;
$BLUE2: #66B7D5;
$BLUE3: #D3EAF3;
$MUSTARD1: #FFDD4A;
$MUSTARD2: #FFEA92;
$MUSTARD3: #FFF8DA;
// Neutral Palette
$WHITE: #FFFFFF;
$LIGHTGRAY: #F7F7F7;
$MIDGRAY: #C5C5C5;
$DARKGRAY: #626262;
$BLACK: #000000;
// Semantic Palette
$LINK: #0079C1;
$SUCCESS: #21AA3E;
$WARNING1: #FFBE2F;
$WARNING2: #C67917;
$ERROR1: #B00020;
$ERROR2: #8E030F;
// Notifications
$PRIMARY: #CFE2FF;
$SUCCESS: #D1E7DD;
$DANGER: #F8D7DA;
$WARNING: #FEF3CD;
// Dark colors
$DARKBACKGROUND: #262729;
$DARKBLOCK: #2e2f31;
$DARKBORDER: #363839;
$DARKTEXT: #c1c1c2;
body {
    //font-family: 'Open Sans Regular';
    font-family: 'Avenir Next W02', 'Avenir Next', 'Helvetica Neue', Helvetica, Arial, sans-serif;
    font-size: 16px;
    color: $BLACK;
}
h1 {font-size: 40px;font-weight:600;}
h2 {font-size: 34px;font-weight:500;}
h3 {font-size: 28px;font-weight:400;}
h4 {font-size: 24px;font-weight:300;}
h5 {font-size: 20px;font-weight:200;}

label {
    font-size: 14px;
    color: $BLACK;
    text-decoration: none;
    text-transform: unset;
    margin-top: 10px;
}
// buttons
button {
	cursor: pointer;
	color: white;
	background-color: $CORAL1;
	border: 2px solid $CORAL1;
	border-radius: 4px;
	padding: 4px 18px;
	margin: 0 4px;
	outline: none;
	&:hover {
		background-color: $NAVY1;
        border-color: $NAVY1;
	}
    &:disabled {
        opacity: 50%;
        cursor: default;
    }
    &:focus {
        outline-style: dashed;
        outline-width: 2px;
        outline-color: #7FADDC;
    }
	&.secondary {
		background-color: white;
		color: $CORAL1;
        &:hover {
			color: $NAVY1;
		}
	}
	&.linkButton {
		background: none;
		border: none;
		border-radius: none;
		padding: 4px 0;
		color: $LINK;
        &:focus {
            outline: none;
        }
		&:hover {
			font-weight:bold;
            background-color:white;
		}
	}
    &.upload {
        background: white;
		border: 2px solid $LINK;
		border-radius: 20px;
		padding: 10px 20px;
		color: $LINK;
        &:focus {
            outline: none;
        }
		&:hover {
            color: $WHITE;
            background-color: $BLUE1;
		}
        
    }
}
.buttonsHolder {
    display: flex;
    justify-content: flex-start;
    height: 30px;
    margin: 10px 0 0 0;
}
// inputs
input[type=text], input[type=password], input[type=number], input[type=email], input[type=tel], textarea {
	border: 1px solid $DARKGRAY;
	background-color: $WHITE;
	border-radius: 4px;
	padding: 7px;
	outline: none;
	margin: 0 3px;
    height: 16px;
    &:hover {
        border-width: 1px;
        border-color: $BLUE2;
        
        //height: 10px;
    }
    &:disabled {
        background-color: $MIDGRAY;
    }
	&:focus {
        outline-width: 1px;
        outline-style: dashed;
        outline-color: #7FADDC;
	}
    &.error {
        border: 2px solid $ERROR2;
    }
}
input[type=checkbox] {
	border: 2px solid $DARKGRAY!important;
	background-color: white;
	outline: none;
}
input[type=radio] {
	width: unset!important;
}
input[type=number] {
    width: 50px;
}
select {
	display: grid;
	padding: 7px;
	width: 100%;
	max-width: 100%;
	box-sizing: border-box;
	margin: 3px;
	border: 1px solid $DARKGRAY;
	box-shadow: 0 1px 0 1px rgba(0,0,0,.04);
	border-radius: 4px;
	background-color: $WHITE;
	&:hover {
		border-width: 4px;
        border-color: $BLUE2;
        margin: 0;
	}
    &:disabled {
        background-color: $MIDGRAY;
    }
	&:focus {
		outline-width: 1px;
        outline-style: dashed;
        outline-color: #7FADDC;
	}
    &.error {
        border: 2px solid $ERROR2;
    }
	option {
		font-weight:normal;
    }
    
}
hr {
	width: 100%;
}
.toggle {
    margin: 1px 4px;
    display: block;
    color: green;
    font-size: 24px;
    &.off {
        color: $DARKGRAY;
    }
}
.icon-button {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    cursor: pointer;
    margin: auto 8px;
    height: 42px;
    .icon-button-contents {
        display: flex;
        flex-flow: column nowrap;
        align-items: center;
        .icon-container {
            font-size: 14px;
        }
        .label-container {
            font-size: 12px;
            text-overflow: ellipsis;
            white-space: nowrap;
            overflow: hidden;
        }
    }
    &:focus {
        outline-style: dashed;
        outline-width: 2px;
        outline-color: #7FADDC;
    }
}
.settings-panel {
    color: #000;
    white-space: initial;
    h4 {
        text-transform: uppercase;
        font-weight: 100;
        margin: 0;
    }
    h5 {
        margin: 10px 0 0 0;
        font: normal normal 600 20px/28px Open Sans;
    }
    .spacer {
        height: 50px;
    }
    input, select {
        width: 400px;
    }
    .toggle-setting {
        display: flex;
        flex-flow: row nowrap;
        align-items: center;
        .toggle {
            display: inline-flex;
        }
        h5 {
            margin: 0 0 0 10px;
        }
    }
    .table-setting {
        .table-area {
            margin: 0;
        }
    }
    .button-container {
        margin-top: 10px;
    }
}
.strength-indicator {
    display: flex;
    flex-flow: row nowrap;

    .strength-level {
        display: flex;
        height: 10px;
        width: 20%;
        margin: 10px 5% 10px 0;
        background-color: $NAVY3;
        border-radius: 10px;
        &.on {
            background-color: $BLUE1;
        }
    }
}

@media (max-width:1000px) {
    .icon-button {
        .icon-button-contents {
            .label-container {
                display: none;
            }
        }
    }
}

.details-row {
    display: flex;
    flex-flow: row nowrap;
    .details-column {
        display: flex;
        flex-flow: column nowrap;
        margin-right: 50px;
    }
}

.color-chooser {
    display: flex;
    flex-flow: row nowrap;
    .color-list {
        display: flex;
        flex-flow: row wrap;
        .color {
            display: flex;
            flex-flow: row wrap;
            width: 50%;
            margin: 5px 0;
            align-items: center;
            cursor: pointer;
            .swatch {
                display: flex;
                width: 24px;
                height: 24px;
                border-radius: 12px;
                border: 1px solid $DARKGRAY;
                margin: 0 4px 0 0;
            }
            &.selected {
                font-weight: bold;
            }
        }
    }
}