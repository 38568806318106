@import './_base';

.main-container {
	margin: 0;
	padding: 0;
    display: flex;
    flex-flow: column nowrap;
    position: absolute;
    height: 100%;
    width: 100%;
    bottom: 0;
	.top-bar {
		display: flex;
		flex-flow: column nowrap;
        height: 51px;
		width: 100%;
		white-space:nowrap;
		background-color: $NAVY1;
		font-size: 13px;
		color: white;
		.top-bar-inner {
			display: flex;
			flex-flow: row nowrap;
			height: 50px;
			justify-content: space-between;
			width: 100%;
			border-bottom: 1px solid white;
			.top-bar-group {
				display: flex;
				flex-flow:row nowrap;
				.top-bar-logo {
					padding: 12px 10px;
					cursor: pointer;
					img {
						width: 150px;
					}
					&:hover {
						background-color: $NAVY2;
					}
				}
				.top-bar-title {
					font-size:24px;
					font-weight:bold;
					text-align:center;
					padding: 8px 0;
					white-space:nowrap;
					overflow-x: hidden;
					text-overflow: ellipsis;
				}
				.top-bar-button {
					display: flex;
					justify-content:center;
					padding: 16px 10px;
					cursor: pointer;
					&.icon {
						font-size:18px;
						width: 30px;
					}
					&:hover {
						background-color: $NAVY2;
					}
					&.selected {
						font-weight:bold;
					}
				}
				.top-bar-divider {
					width: 1px;
					background-color: white;
					margin: 10px 5px;
					height: 30px;
				}
			}
		}
	}
    .app-container {
        display: flex;
        flex-flow: row nowrap;
        position: absolute;
        top: 51px;
        right: 0;
        bottom: 0;
        width: 100%;
        .app-menu-container {
            display: flex;
            flex: 1;
            width: 260px;
            background-color: $NAVY1;
            color: white;
            .app-menu {
                display: flex;
                flex-flow: column nowrap;
                flex-grow: 1;
                justify-content: space-between;
                .app-buttons {
                    display: flex;
                    flex-flow: column nowrap;
                    .app-button {
                        display: flex;
                        padding: 6px 10px;
                        font-size: 24px;
                        //font-weight:bold;
                        border-bottom: 1px solid white;
                        cursor: pointer;
                        .app-icon {
                            display: flex;
                            align-items: center;
                            width: 20px;
                            padding: 0 2px;
                            justify-content: center;
                            font-size: 20px;
                        }
                        &.icon {
                            font-size:18px;
                            width: 20px;
                        }
                        &:hover {
                            background-color: $NAVY2;
                            color: $CORAL1;
                        }
                        &.selected {
                            color: $CORAL1;
                        }
                        .app-button-notify {
                            display: inline;
                            color: $ERROR1;
                            font-size: 10px;
                            padding: 3px 2px 0 0;
                        }
                    }
                }
                .menu-footer {
                    display: flex;
                    padding: 6px 10px;
                    justify-content: center;
                    text-align: center;
                    font-size: 14px;
                }
            }
        }
        .app-area {
            display: flex;
            flex: 5;
            flex-flow: column nowrap;
            overflow-y: auto;
            position: relative;
            bottom: 0;
            .app-portal {
                display:flex;
                flex-flow: column nowrap;
                flex: 1 1;
                margin: 0;
                .title-bar {
                    display: flex;
                    flex-flow: row nowrap;
                    justify-content: space-between;
                    margin: 20px 40px;
                    .title-bar-group {
                        display: flex;
                        flex-flow:row nowrap;
                        .title {
                            font-size: 30px;
                        }
                    }
                }
                .tab-container {
                    display: flex;
                    flex-flow: row nowrap;
                    border-bottom: 1px solid $BLUE2;
                    .tab {
                        border: 1px solid $BLUE2;
                        border-bottom: none;
                        padding: 10px 20px;
                        background-color: $BLUE3;
                        cursor: pointer;
                        &:first-child {
                            margin-left: 40px;
                            border-radius: 4px 0 0 0;
                        }
                        &:last-child {
                            border-radius: 0 4px 0 0;
                        }
                        &.selected {
                            background-color: white;
                        }
                    }
                }
                h3 {
                    padding-left: 40px;
                }
            }
        }
    }
    .tag-row {
        display:flex;
        flex-flow:row wrap;
        background-color:black;
        color:white;
        border-radius:5px;
        font-size:13px;
        margin:2px;
        padding:2px 6px;
        .removal {
            cursor:pointer;
            margin-top:2px;
            margin-left:5px;
        }
    }
	.contact-preview {
		display:flex;
		flex-flow: column nowrap;
		min-height: min-content;
		div {
			//display: flex;
			//flex-flow: row nowrap;
			&.contact-name {
				font-size: 24px;
				justify-content:center;
			}
			&.button-container {
				justify-content:center;
				height: 40px;
			}
		}
		hr {
			width: 100%;
			color: $BLUE2;
		}
	}
	.contact-details {
		display: flex;
		flex-flow: row nowrap;
		position: fixed;
		bottom: 0;
		left: 0;
		right: 0;
		top: 75px;
		.contact-settings-panel {
			margin-top: 100px;
			padding: 15px;
			display: flex;
			flex-flow: column nowrap;
			width: 25%;
			overflow: auto;
			.breadcrumb {
				position: fixed;
				top: 100px;
				left: 0;
				background-color: $BLUE3;
				border-top: 1px solid $BLUE2;
				border-right: 1px solid $BLUE2;
				border-bottom: 1px solid $BLUE2;
				color: $BLUE2;
				border-radius: 0 4px 4px 0;
				text-align:center;
				cursor: pointer;
				.breadcrumb-label {
					margin: 10px;
					font-size: 13px;
				}
				&:hover {
					text-decoration: underline;
				}
			}
			.contact-name {
				font-size: 24px;
				justify-content:center;
				display:flex;
			}
            .contact-id {
                font-size: 12px;
                justify-content:center;
				display:flex;
            }
			.button-container {
				justify-content:center;
				height: 40px;
				display:flex;
			}
			.add-button {
				font-size: 13px;
				cursor: pointer;
				color: $BLUE2;
				&:hover {
					text-decoration: underline;
				}
			}
            .toggle-container {
                display: flex;
                align-items: center;
                label {
                    margin-top: 0;
                }
            }
		}
		.contact-communication-panel {
			display: flex;
			flex-flow: column nowrap;
			width: 50%;
			background-color: $WHITE;
			border-left: 1px solid $LIGHTGRAY;
			border-right: 1px solid $LIGHTGRAY;
			padding: 15px;
			overflow: auto;
			.tablets-outer {
				display: flex;
				flex-flow: row nowrap;
				.tablets-inner {
					display: flex;
					flex-flow: row nowrap;
					justify-content: flex-start;
					font-size: 14px;
					.tablet {
						margin: 10px;
						cursor: pointer;
						&:hover {
							text-decoration: underline;
						}
						&.selected {
							border-bottom: 2px solid;
							font-weight: bold;
						}
					}
				}
			}
			.communication-area-outer {
				display: flex;
				flex-flow: row nowrap;
				.communication-area-inner {
					display: flex;
					flex-direction: column;
					width: 100%;
					.communication-area {
						display: flex;
						flex-direction: column;
						.communication-list {
							.phone-communication {
								display:flex;
								flex-flow:column nowrap;
								color:$DARKGRAY;
								background-color:$WHITE;
								margin-top:10px;
								width:fit-content;
								clear:both;
								float:left;
								max-width:80%;
								&.outbound {
									justify-content: flex-end;
									text-align:right;
									float: right;
									color: $WHITE;
									background-color: $DARKGRAY;
								}
								.info-area {
									display:flex;
									flex-direction:row;
									justify-content:space-between;
									border-bottom:1px solid $DARKGRAY;
									font-size:smaller;
									background-color:$DARKGRAY;
									color:$WHITE;
								}
								.message-area {
									display:flex;
									flex-direction:row;
								}
							}
						}
                        .pcentries {
                            margin: 10px 0;
                            ul {
                                list-style: decimal;
                            }
                        }
					}
				}
			}
		}
		.contact-associations-panel {
			display: flex;
			flex-flow: column nowrap;
			width: 25%;
			padding: 15px;
			overflow: auto;
			.add-button {
				font-size: 13px;
				cursor: pointer;
				color: $BLUE2;
				&:hover {
					text-decoration: underline;
				}
			}
		}
	}
}
.columns-dialog-list {
	display:flex;
	flex-flow: row nowrap;
	.columns-list {
		display: flex;
		flex-flow: column nowrap;
		font-size: 14px;
		min-width: 100px;
		div {
			display: flex;
			margin:4px;
			.dart-checkbox {
				font-size:18px!important;
				border:none;
				width:unset;
				display:flex;
				padding:0;
				margin:0 4px 0 0!important;
			}
			&[id^='filter_'], &[id^='sort_'] {
				color: $LINK;
				cursor: pointer;
			}
		}
	}
}

.showLanding {
    display: flex;
    flex-flow: row wrap;
    .showLandingTile {
        width: 300px;
        display: flex;
        flex-flow: column;
        align-items: center;
        justify-content: center;
        padding: 5px 20px;
        margin: 5px 10px;
        color: $CORAL1;
        border: 1px solid white;
        cursor: pointer;
        h6 {
            font-size: 24px;
            margin: 5px 0;
            text-align: center;
        }
        &:hover {
            border: 1px solid $CORAL1;
            //border-radius: 20px;
            background-color: $CORAL3;
        }
    }
}

@media (max-width:1000px) {
    .app-menu-container {
        width: 50px;
        flex: 0;
    }
    .app-button {
        .app-label {
            display: none;
        }
    }
}